export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '/main',
      redirect: '/',
      component: () => import('@/layouts/Main.vue'),
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import('@views/Dashboard.vue'),
          meta: {
            title: 'Dashboard',
            authRequired: true,
          }
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@views/account/UserEdit.vue'),
          meta: {
            title: 'Account',
            authRequired: true,
          },
        },
        {
          path: '/medical-histories',
          name: 'medical-histories',
          component: () => import('@views/medical-histories/HistoryFind.vue'),
          meta: {
            title: "Hospital Medical Histories",
            authRequired: true,
          },
        },
        {
          path: '/medical-histories/:historyId(\\d+)',
          name: 'history-show',
          component: () => import('@views/medical-histories/HistoryShow.vue'),
          meta: {
            title: "View History",
            authRequired: true,
          },
        },
        {
          path: '/medicine-stock',
          name: 'medicine-stock',
          component: () => import('@views/medicines/MedicineStock.vue'),
          meta: {
            title: "Medicine stock Summary",
            authRequired: true,
          },
        },
        {
          path: '/medicine-requests',
          name: 'medicine-requests',
          component: () => import('@views/medicines/DrugRequests.vue'),
          meta: {
            title: "Medicine Requests",
            authRequired: true,
          },
        },
        {
          path: '/medicine-requests/create',
          name: 'medicine-request-create',
          component: () => import('@views/medicines/DrugRequestCreate.vue'),
          meta: {
            title: "Create Drug Request",
            authRequired: true,
          }
        },
        {
          path: '/medicine-requests/:requestId(\\d+)',
          name: 'medicine-request-show',
          component: () => import('@views/medicines/DrugRequestShow.vue'),
          meta: {
            title: "Drug Request details",
            authRequired: true,
          },
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: () => import('@views/notifications/NotificationList.vue'),
          meta: {
            title: "Notifications",
            authRequired: true,
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
              guestRequired: true,
              title: "Login"
            }
          },
          {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/pages/Error404.vue'),
            meta: {
              title: "404 - Page not Found"
            }
          },
          {
            path: '/error-500',
            name: 'error-500',
            component: () => import('@/views/pages/Error500.vue'),
            meta: {
              title: "500 - Server error"
            }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]