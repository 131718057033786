import env from '../env.js'
import { getUserData } from '@/helpers/utils'

const state = {
    isLoading: false,
    AppActiveUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
      shortDesc: "Medication Management System."
    },
    windowWidth: null,
    countries: [],
    localGovernments: [],
    dashboardData:{
      "hospital": null,
    },
}

export default state
